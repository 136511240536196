import React from "react";

import Languages from "../util/Languages";

const LanguageSelect = ({value, onChange, ...props}) => {
	return (
		<select className="form-control" value={value} onChange={onChange} {...props} required>
			<option value="" disabled>Land auswählen...</option>
			{Languages.map((country, index) => {
				return <option key={index} value={country}>{new Intl.DisplayNames(["de"], {type : "region"}).of(country)}</option>
			})}
		</select>
	);
};

export default LanguageSelect;
