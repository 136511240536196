import React, {useState, useEffect, useCallback} from "react";
import axios from "axios";

import {Modal}       from "react-bootstrap";
import {useAlert}    from "react-alert";

import ColorInput from "../../../../components/shared/ColorInput";

const CategoryEdit = ({show, hide, category, callback}) => {
	const alert                     = useAlert();
	const [shortName, setShortName] = useState(category ? category.shortname : "");
	const [color, setColor]         = useState(category ? category.color : "");

	useEffect(() => {
		if (!category)
			return;

		setShortName(category.shortname);
		setColor(category.color);
	}, [category]);

	const save = useCallback(() => {
		if (!category)
			return;

		const setColor = typeof color === "object" ? color.hex : color;

		const newCategory = {
			...category,
			color     : setColor,
			shortname : shortName
		};

		axios.put(`/vl-categories/${category?.id}`, {...newCategory}).then(() => {
			alert.success(`Die Kategorie '${category?.name}' wurde erfolgreich gespeichert!`);
			callback();
			hide();
		}).catch(error => alert.error(`Fehler beim Speichern der Kategorie: ${error}`));

	}, [shortName, color, category, alert, hide, callback]);

	return (
		<Modal show={show} onHide={() => hide()}>
			<Modal.Header>
				<Modal.Title>Kategorie "{category?.name}" bearbeiten</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="grid thirty-seventy">
					<label>Abkürzung</label>
					<input type="text" className="form-control" name="shortname" value={shortName} onChange={event => setShortName(event.target.value)} />

					<label>Farbe</label>
					<ColorInput value={color} onChange={color => setColor(color)} />
				</section>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-outline-danger" onClick={() => hide()}>Abbrechen</button>
				<button className="btn btn-primary" onClick={save}>Speichern</button>
			</Modal.Footer>
		</Modal>
	);
};

export default CategoryEdit;
