import React, {useState, useEffect, useRef} from "react";

import {BlockPicker} from "react-color";
import {Hash}        from "react-bootstrap-icons";

import Std from "../../utils/Std";

const ColorInput = ({onChange = () => {}, colors = [], value}) => {
	const container         = useRef();
	const [color, setColor] = useState("");
	const [show, setShow]   = useState(false);

	const palette = [
		...colors,
		"#ff6188",
		"#fc9867",
		"#ffd866",
		"#a9dc76",
		"#78dce8",
		"#ab9df2"
	];

	useEffect(() => {
		setColor(value);
	}, [value]);

	useEffect(() => {
		const hidePalette = event => {
			if (container.current && !container.current.contains(event.target))
				setShow(false);
			event.stopImmediatePropagation();
		};
		document.addEventListener("mousedown", hidePalette);

		return () => {
			document.removeEventListener("mousedown", hidePalette);
		};
	}, []);

	const changeColor = color => {
		color = typeof color === "string" ? color : color?.hex;
		setColor(color);
		onChange(color);
	}

	return (
		<>
			<div className="input-group mb-2 mr-sm-2 color-input" style={{position : "relative"}} ref={container}>
				<div className="input-group-prepend">
					<div className="input-group-text" style={{background : color, color : Std.EvaluateForegroundColor(color)}}><Hash /></div>
				</div>
				<input type="text" className="form-control value" minLength={6} maxLength={6} pattern="[a-fA-F0-9]{6}"
				       value={color.replace("#", "")} onFocus={() => setShow(true)}
				       onChange={event => changeColor(`#${event.target.value}`)}
				/>
				{show ?
					<div style={{position : "absolute", left : "50%", transform : "translateX(-50%)", top : "50px"}}>
						<BlockPicker color={color} onChange={color => changeColor(color)} colors={palette} />
					</div> : null
				}
			</div>
		</>
	);
};

export default ColorInput;
