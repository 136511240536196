import React, { useState, useEffect, useCallback } 	from 'react';
import axios 							            from 'axios';

import { Modal, Form }  		        from 'react-bootstrap';
import { useAlert } 				  	from 'react-alert';

import CategoryCheckbox from "../CategoryCheckbox";
import VendorMap        from "../VendorMap";

import LanguageSelect from "../LanguageSelect";

const VendorEdit = ({ show, setShow, currentVendor, callback, categories, resetCurrentVendor }) => {
	const alert       = useAlert();
	const closeScreen = () => setShow(false);
	const [location, setLocation] = useState(null);
	const [formData, setFormData] = useState({
		id: 			"",
		name: 			"",
		city: 			"",
		zip: 			"",
		street: 		"",
		houseNumber: 	"",
		country:		"",
		longitude:		"",
		latitude:		"",
		email: 			"",
		phone: 			"",
		contactPerson:  "",
		homepage:       "",
		facsimile:		"",
		active:         "",
		categories:		[],
	});

	const resetData = () => {
		setFormData({
			id: 			"",
			name: 			"",
			city: 			"",
			zip: 			"",
			street: 		"",
			houseNumber: 	"",
			country:		"",
			longitude:		"",
			latitude:		"",
			email: 			"",
			phone: 			"",
			contactPerson:  "",
			homepage:       "",
			facsimile:		"",
			active:         false,
			categories:		[]
		});
	}

	useEffect(() => {
		if (!currentVendor) {
			resetData();	
			return;
		}

		setFormData({
			id: 			currentVendor?.id,
			name: 			currentVendor?.name,
			city: 			currentVendor?.city,
			zip: 			currentVendor?.zip,
			street: 		currentVendor?.street,
			houseNumber: 	currentVendor?.houseNumber,
			country:		currentVendor?.country,
			email: 			currentVendor?.email,
			phone: 			currentVendor?.phone,
			contactPerson:  currentVendor?.contactPerson,
			homepage:       currentVendor?.homepage,
			facsimile:		currentVendor?.facsimile,
			categories:		currentVendor?.categories,
			active         : currentVendor?.active
		});
	}, [currentVendor]);

	const searchCoords = useCallback((force = false) => {
		if (!currentVendor)
			return;

		if (force || (!currentVendor.longitude || !currentVendor.latitude)) {
			axios.get(`https://nominatim.openstreetmap.org/?street=${currentVendor?.houseNumber} ${currentVendor?.street}&postalcode=${currentVendor?.zip}&country=${currentVendor?.country.toLowerCase()}&addressdetails=1&format=json&limit=1`, {transformRequest: (data, headers) => {
					delete headers.common["Authorization"];
					return data;
				}}).then(response => {
				if (response.data.length < 1)
					return;
				const data = response.data[0];
				if (!data.lat || !data.lon)
					return;
				setLocation({lat : data.lat, lng : data.lon});
			}).catch(error => alert.error(`Fehler beim Abruf der Kartendaten: ${error}`));
		} else {
			setLocation({lat : currentVendor.latitude, lng : currentVendor.longitude});
		}
	}, [currentVendor, alert]);

	useEffect(() => {
		searchCoords();
	}, [currentVendor, searchCoords]);

	const handleInput = (event) => {
		const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;

		setFormData({ ...formData, [event.target.name]: value });
	};

	const handleCheckbox = event => {
		const newData       = {...formData};

		/** We need this check, otherwise the push method throws a new error for adding a new vendor */
		if(newData.categories === undefined) {
			newData.categories = [];
		}

		for (let i = 0; i < newData.categories.length; i++) {
			const category = newData.categories[i];
			if (category._id !== event.target.value)
				continue;
			newData.categories.splice(i, 1);
		}

		if (event.target.checked) {
			for (const category of categories) {
				if (category.id !== event.target.value)
					continue;
				newData.categories.push(category);
			}
		}
		setFormData(newData);
	}

	const save = (event) => {
		event.preventDefault();
		const newData = {
			...formData,
			latitude  : location?.lat,
			longitude : location?.lng
		};

		if (!location?.lat)
			delete newData.latitude;
		if (!location?.lng)
			delete newData.longitude;

		delete newData.id;

		//** Using the flag property to decide the HTTP Method */
		if (currentVendor) {
			axios.put(`/vl-vendors/${formData?.id}`, newData).then((_) => {
				alert.success(`Händler ${formData.name} wurde erfolgreich geupdated.`);
				callback();
				closeScreen();
			}).catch(error => {
				console.log(error.response);
				alert.error(`Fehler beim Updaten des Händlers ${formData?.name}: ${error}`);
				closeScreen();
			});
		} else {
			axios.post("/vl-vendors", newData).then((_) => {
				alert.success(`Händler ${formData?.name} wurde erfolgreich angelegt.`);
				callback();	
				resetData();
				closeScreen();
			}).catch((error) => {
				console.log(error.response);
				alert.error(`Fehler beim Anlegen des Händlers ${formData?.name}.`);
				closeScreen();
			})
		}
	};

	return (
		<Modal className="vendorlocator modal edit" show={show} size="xl" scrollable={true} onHide={closeScreen}>
			<Modal.Header closeButton={true}>
				<Modal.Title>
					{currentVendor ? `${currentVendor.name} bearbeiten` : "Neuen Händler anlegen"}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="grid half-half">
					<section className="grid thirty-seventy">
						<h4 style={{gridColumn : "1 / span 2"}}>Stammdaten</h4>
						<label>Aktiv</label>
						<Form.Check type="switch" name="active" onChange={handleInput} checked={formData?.active || 0} label="" id="active" />
						<label>Firmenname</label>
						<input className="form-control" onChange={handleInput} type="text" name="name" value={formData?.name || ''} />
						<label>Ort</label>
						<input className="form-control" onChange={handleInput} type="text" name="city" value={formData?.city || ''} />
						<label>Postleitzahl</label>
						<input className="form-control" onChange={handleInput} type="text" name="zip" value={formData?.zip || ''} />
						<label>Adresse</label>
						<section className="grid seventy-thirty" style={{padding : 0}}>
							<input className="form-control" onChange={handleInput} type="text" name="street" value={formData?.street || ''} placeholder="Straße" />
							<input className="form-control" onChange={handleInput} type="text" name="houseNumber" value={formData?.houseNumber || ''} placeholder="Hausnr." />
						</section>
						<label>Land</label>
						<LanguageSelect name="country" onChange={handleInput} value={formData?.country || ""} />
						<label>E-Mail</label>
						<input className="form-control" onChange={handleInput} type="email" name="email" value={formData?.email || ''} />
						<label>Telefon</label>
						<input className="form-control" onChange={handleInput} type="text" name="phone" value={formData?.phone || ''} />
						<label>Telefax</label>
						<input className="form-control" onChange={handleInput} type="text" name="facsimile" value={formData?.facsimile || ''} />
						<label>Kontaktperson</label>
						<input className="form-control" onChange={handleInput} type="text" name="contactPerson" value={formData?.contactPerson || ''}/>
						<label>Homepage</label>
						<input className="form-control" onChange={handleInput} type="text" name="homepage" value={formData?.homepage || ''}/>
						<label>Kategorien</label>
						<section className="category-grid" style={{padding : 0}}>
							{categories?.map((category, index) => {
									return <CategoryCheckbox key={index} category={category} value={formData?.categories?.map(category => category._id).includes(category._id)} onChange={handleCheckbox} />
								}
							)}
						</section>
					</section>
					{currentVendor && currentVendor.street && currentVendor.houseNumber && currentVendor.zip && currentVendor.country && location ?
					<section>
						<h4>Standort</h4>
						<section className="row">
							<div className="col-auto">
								<div className="input-group mb-2">
									<div className="input-group-prepend">
										<div className="input-group-text">lat</div>
									</div>
									<input type="text" className="form-control" id="inlineFormInputGroup" value={location?.lat} readOnly disabled />
								</div>
							</div>
							<div className="col-auto">
								<div className="input-group mb-2">
									<div className="input-group-prepend">
										<div className="input-group-text">lon</div>
									</div>
									<input type="text" className="form-control" id="inlineFormInputGroup" value={location?.lng} readOnly disabled />
								</div>
							</div>
						</section>
						<VendorMap location={location} setLocation={setLocation} />
						<button className="btn btn-secondary btn-block" disabled={!location} onClick={() => searchCoords(true)}>zurücksetzen</button>
					</section> : null}
				</section>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-outline-danger"
				        onClick={() =>{
							closeScreen();
							resetCurrentVendor(null);
						}}
				>
					Abbrechen
				</button>
				<button className="btn btn-success" onClick={save}>Speichern</button>
			</Modal.Footer>
		</Modal>
	);
};

export default VendorEdit;
