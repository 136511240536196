import React from "react";
import Std from "./../../../utils/Std";


const CategoryCheckbox = ({category, value = false, onChange}) => {

	return (
		<>
			<span className="tag" style={{
				background : category.color,
				color      : Std.EvaluateForegroundColor(category.color),
			}}>
			<input type="checkbox" value={category._id} checked={value} id={`tag_${category._id}`} onChange={event => onChange(event)} />
			<label htmlFor={`tag_${category._id}`}>{category?.name}</label>
			</span>
		</>
	)
}

export default CategoryCheckbox;
