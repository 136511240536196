import React from "react";
import Std                          from "../../../utils/Std";

const CategoryButton = ({category, active, onClick}) => {
    return(
        <>
            <span className="tag" style={{
                background : active ? "rgba(0,0,0,0.1)" : category.color,
                color      : active ? category.color : Std.EvaluateForegroundColor(category.color),
                border     : active ? `solid 1px ${category.color}` : "solid 1px transparent"
            }} onClick={onClick}>
                {category.name}
            </span>
        </>
    )
}

export default CategoryButton;
