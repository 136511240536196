import React, {useRef, useMemo, useEffect} from "react";

import {MapContainer, Marker, TileLayer, useMap} from "react-leaflet";

import L from 'leaflet';


const INITIAL_ZOOM     = 18;
const DEFAULT_LOCATION = {lat : 49.749992, lng : 6.6371433};

const DraggableMarker = ({location, setLocation}) => {
	const markerRef               = useRef(null)
	const eventHandlers           = useMemo(() => ({
		dragend() {
			const marker = markerRef.current
			if (marker !== null)
				setLocation(marker.getLatLng())
		}
	}), [setLocation]);

	return (
		<Marker
			draggable={true}
			eventHandlers={eventHandlers}
			position={location || DEFAULT_LOCATION}
			ref={markerRef}>
		</Marker>
	)
}

const VendorMap = ({location, setLocation}) => {
	const UpdateView = ({location}) => {
		const map = useMap();
		location  = !location ? DEFAULT_LOCATION : location;
		map.setView(location, INITIAL_ZOOM);

		return null;
	}

	useEffect(() => {
		delete L.Icon.Default.prototype._getIconUrl;
		
		L.Icon.Default.mergeOptions({
			iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
			iconUrl: require('leaflet/dist/images/marker-icon.png'),
			shadowUrl: require('leaflet/dist/images/marker-shadow.png')
		});
	},[])

	return (
		<MapContainer center={location || DEFAULT_LOCATION} zoom={INITIAL_ZOOM} scrollWheelZoom={true}>
			<TileLayer url="https://api.maptiler.com/maps/streets/256/{z}/{x}/{y}@2x.png?key=YcHOlCUfUQjU2rA4uyGi" />
			<DraggableMarker location={location} setLocation={setLocation} />
			<UpdateView location={location || DEFAULT_LOCATION} />
		</MapContainer>
	);
};

export default VendorMap;
