import React, {useState, useEffect, useCallback, useContext} from "react";
import axios from "axios";

import {useAlert}               from "react-alert";
import {InfoCircle, SquareFill} from "react-bootstrap-icons";

import UserContext   from "../../../components/UserContext";
import SortableTable from "../../../components/shared/SortableTable";
import Spinner       from "../../../components/shared/Spinner";

import CategoryEdit from "./modals/CategoryEdit";

const Headers = [
	{
		label  : "Id",
		member : "id",
		width  : "90px"
	},
	{
		label     : "",
		member    : "color",
		sortable  : false,
		width     : "50px",
		formatter : value => <SquareFill style={{color : value}} />
	},
	{
		label  : "Bezeichnung",
		member : "name"
	},
	{
		label  : "Kurzbezeichnung",
		member : "shortname"
	}
];

const CategoryList = () => {
	const {permissions}               = useContext(UserContext);
	const alert                       = useAlert();
	const [categories, setCategories] = useState([]);
	const [category, setCategory]     = useState(null);
	const [showEdit, setShowEdit]     = useState(false);

	const load = useCallback(() => {
		axios.get("/vl-categories").then(response => {
			setCategories(response.data);
		}).catch(error => alert.error(`Fehler beim Laden der Kategorien: ${error}`));
	}, [alert]);

	useEffect(() => {
		load();
	}, [load]);

	const hideEdit = () => {
		setCategory(null);
		setShowEdit(false);
	}

	if (categories.length === 0)
		return (
			<>
				<h2 className="py-4">Kategorien</h2>
				<Spinner />
			</>
		);

	return (
		<>
			<h2 className="py-4">Kategorien</h2>
			<article className="alert alert-warning">
				<InfoCircle className="big" style={{verticalAlign : "middle"}} />
				Kategorien werden über das marvolo. Backend administriert.
			</article>
			<SortableTable headers={Headers} data={categories} rowCallback={!permissions.application.controllers["vl-categories"].update.enabled ? null : member => {
				setCategory(member);
				setShowEdit(true);
			}}  />
			<CategoryEdit show={showEdit} hide={hideEdit} category={category} callback={() => load()} />
		</>
	);
};

export default CategoryList;
