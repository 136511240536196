import React, {useState, useContext, useEffect} from "react";
import {NavLink, Route, Routes}                 from "react-router-dom";

import {ZoomIn} 								from "react-bootstrap-icons";

import UserContext 								from "../../components/UserContext";

import VendorList   							from "./components/VendorList";
import CategoryList 							from "./components/CategoryList";

import "./_style.scss";

const VendorLocator = ({type}) => {
	const {permissions}           = useContext(UserContext);
	const [ready, setReady]       = useState(false);

	useEffect(() => {
		if (typeof permissions.application !== "undefined")
			setReady(true);
	}, [permissions]);

	if (!ready || !permissions.application.controllers["vl-vendor"].create.enabled)
		return <></>

	if (type === "nav")
		return <NavLink to="/VendorLocator" className={ ({isActive}) => isActive ? "active" : ""}><ZoomIn /> <span>Händlersuche</span></NavLink>

	return (
		<section className="vendorlocator">
			<nav>
				<ul className="nav nav-tabs">
					<li className="nav-item"><NavLink end className="nav-link" to="/VendorLocator">Übersicht</NavLink></li>
					<li className="nav-item"><NavLink end className="nav-link" to="/VendorLocator/categories">Kategorien</NavLink></li>
				</ul>
			</nav>
			<Routes>
				<Route exact index element={<VendorList />} />
				<Route exact path="categories" element={<CategoryList />} />
			</Routes>
		</section>
	);
};

export default VendorLocator
