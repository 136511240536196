import React, { useState, useEffect, useCallback, useContext, useRef, useMemo } from "react";
import axios from "axios";

import {Pencil, Trash, PersonPlusFill, CheckSquare, XSquare, Search, GeoAlt, ListNested, X} from "react-bootstrap-icons";
import { useAlert } from "react-alert";

import UserContext from "../../../components/UserContext";

import EmailFormatter       from "../../../formatters/EmailFormatter";
import PhoneNumberFormatter from "../../../formatters/PhoneNumberFormatter";
import SortableTable        from "../../../components/shared/SortableTable";
import FlagFormatter        from "../../../formatters/FlagFormatter";
import YesNoModal           from "../../../components/shared/YesNoModal";
import Spinner              from "../../../components/shared/Spinner";
import Std                  from "../../../utils/Std";

import VendorEdit       from "../components/modals/VendorEdit";
import CategoryButton   from "../components/CategoryButton";

const VendorList = () => {
	const {permissions}                   = useContext(UserContext);
	const alert                           = useAlert();
	const search                          = useRef();
	const [vendors, setVendors]           = useState([]);
	const [filtered, setFiltered]         = useState([]);
	const [filterId, setFilterId]         = useState(null);
	const [filterString, setFilterString] = useState("");
	const [vendor, setVendor]             = useState(null);
	const [categories, setCategories]     = useState([]);
	const [showDelete, setShowDelete]     = useState(false);
	const [showEdit, setShowEdit]         = useState(false);

	const load = useCallback(() => {
		axios.get("/vl-vendors?_limit=-1").then(response => {
			setVendors(response.data);
			setFiltered(response.data);
		}).catch(error => alert.error(`Fehler beim Laden der Händlerliste: ${error}`));
	}, [alert]);

	const handleCategoryTags = useCallback((member, category) => {
		let categoryIds = member.categories.map(category => category.id);

		if (categoryIds.includes(category.id))
			categoryIds = categoryIds.filter(id => id !== category.id);
		else
			categoryIds.push(category.id);

		const vendor = {
			...member,
			categories : [...categoryIds]
		};

		axios.put(`/vl-vendors/${member.id}`, vendor).then(() => {
			load();
		}).catch(error => alert.error(`Fehler beim Entfernen der Kategorie ${category.name} von ${member.name}: ${error}`));
	}, [alert, load]);

	const headers = useMemo(() => [
		{
			label  : "Id",
			member : "internalId",
			width  : "90px",
			sortable: false
		},
		{
			label     : "",
			member    : "active",
			width     : "50px",
			sortable  : false,
			formatter : (active) =>	active ? <CheckSquare style={{ opacity: 1 }} className="text-success" /> :
					<XSquare style={{ opacity: 1 }} className="text-danger" />
		},
		{
			label  : "Name",
			member : "name",
		},
		{
			label     : "Land",
			member    : "country",
			width     : "100px",
			formatter : FlagFormatter,
		},
		{
			label  : "PLZ",
			member : "zip",
			width  : "100px"
		},
		{
			label  : "Ort",
			member : "city",
		},
		{
			label      : "Straße",
			member     : null,
			sortable   : false,
			functional : ({member}) => `${member.street} ${member.houseNumber}`
		},
		{
			label     : "Telefon",
			member    : "phone",
			formatter : PhoneNumberFormatter,
			sortable  : false
		},
		{
			label     : "E-Mail",
			member    : "email",
			formatter : EmailFormatter,
		},
		{
			label       : "Kategorien",
			member      : "categories",
			fullContent : true,
			sortable    : false,
			functional  : ({ member }) => {
				return (
					<>
						{categories.map((category, index) => {

							return (
								<label className="mr-1 ml-3 tag" style={{
									background : category.color || "inherit",
									color      : Std.EvaluateForegroundColor(category.color),
									opacity    : member.categories.map(category => category._id).includes(category._id) ? 1.0 : 0.3
								}} key={index} onClick={!permissions.application.controllers["vl-vendor"].update.enabled ? null : () => handleCategoryTags(member, category)}>
									{category.shortname}
								</label>
							);
						})}
					</>
				);
			},
		},
		{
			label  : "",
			member : null,
			sortable: false,
			width: "50px",
			functional : ({member}) => !member.latitude || !member.longitude ? null : <span className="text-success"><GeoAlt className="big" style={{opacity : 1}} /></span>
		},
		{
			label      : "",
			member     : null,
			sortable   : false,
			align      : "right",
			width      : "90px",
			functional : ({member}) => {
				if (!permissions.application.controllers["vl-vendor"].update.enabled)
					return null;
				return (
					<>
						{!permissions.application.controllers["vl-vendor"].update.enabled ? null :
							<button className="btn" onClick={() => {
								setVendor(null);
								setVendor(member);
								setShowEdit(true);
							}} title="Bearbeiten">
								<Pencil/>
							</button>
						}
						{!permissions.application.controllers["vl-vendor"].delete.enabled ? null :
							<button	className="btn"	onClick={() => { setVendor(member);	setShowDelete(true); }}	title="Löschen">
								<Trash />
							</button>
						}
					</>
				);
			}
		}
	], [categories, permissions, handleCategoryTags]);

	const loadCategories = useCallback(() => {
		axios.get("/vl-categories").then((response) => {
			setCategories(response.data);
		}).catch(error => alert.error(`Fehler beim Laden der Kategorien: ${error}`));
	}, [alert]);

	useEffect(() => {
		search.current.focus();
		load();
		loadCategories();
	}, [load, loadCategories]);

	// filter handling
	useEffect(() => {
		setFiltered(vendors.filter(vendor => {
			const regex = new RegExp(`^(${filterString}.*)`, "gi");
			let valid   = true;

			if (filterString.length > 0) {
				valid = vendor.name.match(regex) !== null;
			}
			if (valid && filterId) {
			 	valid = vendor.categories.map(category => category.id).includes(filterId);
			}

			return valid;
		}));
	}, [filterString, vendors, filterId]);

	/*
	 * How handleCategoryTags should work
	 *
	 *  1. Get the actual member
	 *  2. Get the categories of this member
	 * 	3. Get the clicked category
	 *  4. Add or remove the clicked category from the categories array of this member
	 *  5. Update in database
	 *
	 */
	const deleteVendor = () => {
		if (!vendor)
			return;

		axios.delete(`/vl-vendors/${vendor.id}`).then(() => {
			alert.success(`Der Händler '${vendor.name}' wurde erfolgreich gelöscht!`);
			setVendor(null);
			load();
		}).catch(error => alert.error(`Fehler beim Löschen des Händlers '${vendor.name}': ${error}`));
	};

	return (
		<>
			<h2 className="py-4">
				Händlerliste
				<button className="btn btn-success btn-sm float-right" onClick={() => {setVendor(null); setShowEdit(true)}}>
					<PersonPlusFill className="big" />
					Händler anlegen
				</button>
			</h2>
			<section className="filter grid three-thirds">
				<div className="input-group">
					<div className="input-group-prepend">
						<div className="input-group-text"><Search /></div>
					</div>
					<input type="text" ref={search} className="form-control" placeholder="Suche..." value={filterString} onChange={event => setFilterString(event.target.value)} />
					{
						filterString.length > 0 ?
							<button type="button" className="btn bg-transparent" style={{marginLeft: "-40px", zIndex: 100}} onClick={() => setFilterString("")}>
								<X />
							</button> : null
					}
				</div>
			</section>
			<section className="legend">
				<h5 className="fancy">Händlerkategorien</h5>
				<p className="description">
					(zum Filtern klicken)
				</p>
				{categories.map((category, index) =>
						<CategoryButton key={index} category={category} active={filterId === category.id} onClick={() => setFilterId(category.id)} />
				)}
				{
					filterId ?
						<span title="Kategoriefilter zurücksetzen" className="tag" style={{background : "#aaa", color : "black"}} onClick={() => setFilterId(null)}>X</span>
					: null
				}
			</section>
			{ vendors.length < 1 ?
				<Spinner /> :
				<>
					{ filtered && filtered.length > 0 ?
						<SortableTable data={filtered} headers={headers} sorting="name" /> :
						<section className="noresult">
							<ListNested />
							Leider keine Ergebnisse
						</section>
					}
				</>
			}
			<YesNoModal show={showDelete} setShow={setShowDelete} title="Händler löschen" text={`Möchten Sie den Händler '${vendor?.name}' wirklich löschen?`} callback={deleteVendor} />
			<VendorEdit show={showEdit} setShow={setShowEdit} categories={categories} currentVendor={vendor} resetCurrentVendor={setVendor} callback={load} />
		</>
	);
};

export default VendorList;
